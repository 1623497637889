<template lang="">
 <div>
  <b-breadcrumb class="breadcrumb-pipes">
    <b-breadcrumb-item href="/settings/admin-panel/">
          Admin Panel
        </b-breadcrumb-item>
        <b-breadcrumb-item href="/settings/user-role">
          Roles and Permissions
        </b-breadcrumb-item>
        <b-breadcrumb-item href="/settings/personal-settings">
          Personal Settings
        </b-breadcrumb-item>
      </b-breadcrumb>
  <b-card class="mb-0" :title="$t('settings.email.manage_emails')">
   
    <b-overlay :show="loading" rounded="sm" no-fade>
      <validation-observer ref="updateValidation">
        <b-row class="p-2">
          <!-- Mail To -->
          <b-col cols="6" sm="12" md="4" lg="4">
            <b-form-group label-for="email" :label="$t('settings.email.mail_to')">
              <div v-for="(email, index) in mailTo" :key="'mailTo-' + index" class="d-flex align-items-center mb-2">
       
                  <b-form-input
                    v-model="mailTo[index]"
                    name="mailTo"
                    :placeholder="$t('settings.email.mail_to')"
                    class="mr-2"
                  />
       
                <b-button variant="danger" @click="removeEmail(mailTo, index)">Remove</b-button>
              </div>
              <b-button variant="primary" @click="addEmail(mailTo)">Add Email</b-button>
            </b-form-group>
          </b-col>

          <!-- Mail CC -->
          <b-col cols="6" sm="12" md="4" lg="4">
            <b-form-group label-for="email" :label="$t('settings.email.mail_cc')">
              <div v-for="(email, index) in mailCC" :key="'mailCC-' + index" class="d-flex align-items-center mb-2">
  
                  <b-form-input
                    v-model="mailCC[index]"
                    name="mailCC"
                    :placeholder="$t('settings.email.mail_cc')"
                    class="mr-2"
                  />
     
                <b-button variant="danger" @click="removeEmail(mailCC, index)">Remove</b-button>
              </div>
              <b-button variant="primary" @click="addEmail(mailCC)">Add Email</b-button>
            </b-form-group>
          </b-col>

          <!-- Mail BCC -->
          <b-col cols="6" sm="12" md="4" lg="4">
            <b-form-group label-for="email" :label="$t('settings.email.mail_bcc')">
              <div v-for="(email, index) in mailBCC" :key="'mailBCC-' + index" class="d-flex align-items-center mb-2">
  
                  <b-form-input
                    v-model="mailBCC[index]"
                    name="mailBCC"
                    :placeholder="$t('settings.email.mail_bcc')"
                    class="mr-2"
                  />
            
                <b-button variant="danger" @click="removeEmail(mailBCC, index)">Remove</b-button>
              </div>
              <b-button variant="primary" @click="addEmail(mailBCC)">Add Email</b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-h="end" class="p-2">
          <b-col v-if="$Can('edit_emails')" cols="12" md="3">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="success" @click="updateEmails">
                <span class="text-nowrap">{{$t('settings.email.update_emails')}}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
  </b-card>
</div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import settingsStoreModule from "../../settingsStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, min, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import { BBreadcrumb, BBreadcrumbItem } from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BBreadcrumb, BBreadcrumbItem
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = "settings";
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
      store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
        store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },

  data() {
  return {
    mailTo: [],  // Initialize as empty arrays
    mailCC: [],
    mailBCC: [],
    loading: false,
    currentMailTo: [],
    currentMailCC: [],
    currentMailBCC: [],
  };
},
computed: {
  user: {
    get() {
      return this.$store.getters["authUser"];
    },
    set(newValue) {
      return this.$store.dispatch("setUser", newValue);
    },
  },
},
mounted() {
  this.getEmails();
},

watch: {
  mailTo(newVal) {
    this.checkForDuplicates(newVal, 'mailTo');
  },
  mailCC(newVal) {
    this.checkForDuplicates(newVal, 'mailCC');
  },
  mailBCC(newVal) {
    this.checkForDuplicates(newVal, 'mailBCC');
  },
},
methods: {
  addEmail(emailArray) {
    emailArray.push('');  // Add a new empty string to create a new input field
  },
  removeEmail(emailArray, index) {
    emailArray.splice(index, 1);  // Remove the email at the given index
  },
  getEmails() {
    this.loading = true;
    store
      .dispatch("settings/fetchEmails")
      .then((res) => {
        if (res.data.length > 0) {
          res.data.forEach((email) => {
            if (email.Name == "emailCC") {
              this.mailCC = Array.isArray(email.Value) ? email.Value : [];  // Ensure it's an array
              this.currentMailCC = [...this.mailCC];  // Make a copy
            }

            if (email.Name == "emailTo") {
              this.mailTo = Array.isArray(email.Value) ? email.Value : [];  // Ensure it's an array
              this.currentMailTo = [...this.mailTo];  // Make a copy
            }

            if (email.Name == "emailBCC") {
              this.mailBCC = Array.isArray(email.Value) ? email.Value : [];  // Ensure it's an array
              this.currentMailBCC = [...this.mailBCC];  // Make a copy
            }
          });
        }
        this.loading = false;
      })
      .catch((error) => {
        this.databaseErrorMessage();
        console.log(error);
      });
  },
  updateEmails() {
    this.$refs.updateValidation.validate().then((success) => {
      if (success) {
        if (
          JSON.stringify(this.currentMailCC) === JSON.stringify(this.mailCC) &&
          JSON.stringify(this.currentMailTo) === JSON.stringify(this.mailTo) &&
          JSON.stringify(this.currentMailBCC) === JSON.stringify(this.mailBCC)
        ) {
          this.noChangesToastMessage();
        } else {
          this.loading = true;
          store
            .dispatch("settings/updateEmails", {
              emails: {
                emailTo: this.mailTo.join(','),  // Convert array back to string
                emailCC: this.mailCC.join(','),
                emailBCC: this.mailBCC.join(',')
              },
            })
            .then((res) => {
              if (res.data.info === true) {
                this.loading = false;
                this.getEmails();
                this.updateEmailToastMessage();
              } else {
                this.loading = false;
                this.wrongToastMessage(res.data.message);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    });
  },
  updateEmailToastMessage() {
    this.$toast({
      component: ToastificationContent,
      props: {
        title: "Successfully",
        text: this.$t('settings.email.email_update_success'),
        icon: "ThumbsUpIcon",
        variant: "success",
      },
    });
  },
  databaseErrorMessage() {
    this.$toast({
      component: ToastificationContent,
      props: {
        title: "Error",
        text: this.$t('settings.email.database_connect_error'),
        icon: "AlertTriangleIcon",
        variant: "danger",
      },
    });
  },
  noChangesToastMessage() {
    this.$toast({
      component: ToastificationContent,
      props: {
        title: "Error",
        text: this.$t('settings.email.make_not_any_changes'),
        icon: "AlertTriangleIcon",
        variant: "danger",
      },
    });
  },
  
  checkForDuplicates(emailArray, type) {
    const uniqueEmails = new Set();
  const duplicates = new Set();

  emailArray.forEach((email) => {
    if (uniqueEmails.has(email)) {
      duplicates.add(email);  // Mark duplicates
    } else {
      uniqueEmails.add(email);
    }
  });

  if (duplicates.size > 0) {
    this.$toast({
      component: ToastificationContent,
      props: {
        title: "Duplicate Email",
        text: `The following email(s) are duplicated and the last entry will be removed: ${Array.from(duplicates).join(', ')}`,
        icon: "AlertTriangleIcon",
        variant: "danger",
      },
    });

    // Remove only the last occurrence of each duplicate from the respective array
    duplicates.forEach((duplicateEmail) => {
      const lastIndex = emailArray.lastIndexOf(duplicateEmail);
      if (lastIndex !== -1) {
        emailArray.splice(lastIndex, 1);  // Remove the last occurrence
      }
    });

    // Update the email array
    this[type] = [...emailArray];
  }
  },
},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
